.tds-verify-form-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tds-verify-title {
  text-align: center;
}
.tds-verify-form {
  max-width: 486px;
  min-width: 200px;
  min-height: 30vh;
  width: 100%;
}

.tds-verify-btn-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tds-verify-btn-container button {
  margin-right: 0;
}

.tds-verify-btn-center {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.tds-verify-btn-center button {
  margin-right: 0;
}

.tds-verify-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000001;
}

.tds-verify-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #008a00;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tds-verify-container {
  position: relative;
}
