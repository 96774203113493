/* Mobile */

/* 
    DO NOT MODIFY DIRECTLY IN PROJECT gsbm-continuous-testing

    ALWAYS COPY FROM tds-one-portal-static master branch

*/

/* Desktop */

.tds-mobile-nav {
  display: none;
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-mobile-nav {
    display: block;
  }

  .tds-mobile-nav-header {
    height: 45px;
    padding: 7px 20px;
    position: fixed;
    width: 100%;
    top: 0px;
    background-color: white;
    z-index: 100;
    box-shadow: 0 4px 2px -2px #ccc;
  }

  .tds-logo-mobile {
    float: left;
  }

  .tds-logo-mobile img {
    height: 31px;
  }

  .tds-mobile-nav-header .td-icon-hamburger {
    float: left;
    color: #038203;
    padding-right: 5px;
  }

  .tds-mobile-nav-header .td-icon-avatar,
  .tds-mobile-nav-header .td-icon-bookAppointment {
    float: right;
    color: #038203;
  }

  .tds-mobile-nav-header .td-icon-avatar {
    padding-left: 10px;
  }

  .td-icon.icon-mobile {
    font-size: 31px;
  }

  .tds-mobile-nav-left.active {
    display: block;
    transition: 2s;
    left: -395px;
    height: 100%;
    -webkit-animation: slide-right 0.5s forwards;
    animation: slide-right 0.5s forwards;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0px;
    position: fixed;
  }

  @-webkit-keyframes slide-right {
    100% {
      left: 0;
    }
  }

  @keyframes slide-right {
    100% {
      left: 0;
    }
  }

  .tds-mobile-nav-left {
    width: 395px;
    position: absolute;
    z-index: 110;
    left: -100px;
    background-color: white;
    display: none;
  }

  .tds-mobile-nav-left:after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    box-shadow: 15px 0 15px -15px inset;
    right: -15px;
  }

  .tds-mobile-navbar {
    list-style: none;
    margin: 0px;
    padding: 0px 0px 10px 27px;
    clear: both;
    float: left;
    width: 100%;
  }

  .tds-mobile-nav-right .tds-mobile-nav-logo-container {
    border-bottom: 1px solid #dadada;
  }

  .tds-mobile-nav-logo-container {
    padding: 7px 20px;
    float: left;
    width: 100%;
  }

  .tds-mobile-nav-logo-container .tds-logo-mobile {
    float: left;
  }

  .tds-mobile-nav-logo-container .td-icon-close {
    float: right;
  }

  .tds-mobile-nav-container {
    clear: both;
  }

  .tds-mobile-search-container {
    padding: 0px 40px 0px 30px;
  }

  .tds-mobile-search-container.form-group {
    margin-bottom: 10px;
  }

  .tds-mobile-search-container.form-group .form-control {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  .tds-mobile-search-container.form-group :focus,
  .tds-mobile-search-container.form-group :hover {
    border-bottom-width: 1px;
    border-color: #dadada;
    outline: 0;
  }

  .tds-mobile-search-container.form-group .input-group .input-group-prepend {
    left: 0px;
  }

  .tds-mobile-search-container.form-group .input-group .input-group-text {
    color: #008a00;
  }

  .tds-mobile-navbar-home {
    background-color: #f3f3f3;
    padding: 15px 27px;
  }

  .tds-mobile-navbar-home a {
    font-size: 16px;
    font-family: "TD Graphik Medium";
    color: #1c1c1c;
  }

  .tds-mobile-nav-background-overlay {
    display: none;
  }

  .tds-mobile-nav-background-overlay.active {
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: black;
    z-index: 105;
    opacity: 0.5;
    display: block;
    top: 0;
  }

  .tds-mobile-nav-right {
    display: none;
  }

  .tds-mobile-nav-right.active {
    display: block;
    transition: 2s;
    right: -395px;
    top: 0px;
    height: 100%;
    -webkit-animation: slide-left 0.5s forwards;
    animation: slide-left 0.5s forwards;
    overflow-y: scroll;
    position: fixed;
  }

  // .tds-mobile-nav-right .tds-mobile-navbar .tds-mobile-navbar-item:first-child {
  //   border-top: none;
  // }

  .tds-mobile-nav-right li {
    padding: 10px 0px;
    border-bottom: 1px solid #ccc;
  }

  @-webkit-keyframes slide-left {
    100% {
      right: 0;
    }
  }

  @keyframes slide-left {
    100% {
      right: 0;
    }
  }

  .tds-mobile-nav-right {
    width: 395px;
    position: absolute;
    z-index: 110;
    right: -395px;
    background-color: white;
    display: none;
  }

  .tds-mobile-nav-right:after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    box-shadow: 15px 0 15px -15px inset;
    left: -15px;
  }

  .tds-mobile-nav-right .td-icon-avatar {
    float: left;
    padding: 10px 0px;
    color: #038203;
  }

  .tds-mobile-nav-right .td-icon-close {
    padding: 10px 0px;
  }

  .tds-mobile-nav-search {
    display: none;
    font-size: 12px;
  }

  .tds-mobile-nav-search.active {
    display: block;
    position: absolute;
    top: 45px;
    height: 45px;
    width: 100%;
    background-color: #f3f3f3;
  }

  .tds-mobile-nav-search .tds-mobile-search-input {
    margin: 9px 27px;
    width: 75%;
    border: none;
    padding: 4px;
  }

  .tds-mobile-nav-search .td-icon-close {
    float: right;
    padding: 16px 20px;
  }

  .tds-mobile-nav-search .td-icon.icon-search {
    font-size: 12px;
  }
  .tds-mobile-nav-left .td-icon.td-icon-checkMark.icon-small {
    float: right;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
}

/* MOBILE */

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 375px) {
  .tds-mobile-nav-left {
    width: 295px;
  }

  .tds-mobile-nav-left.active {
    left: -295px;
  }

  .tds-mobile-nav-right {
    width: 295px;
  }

  .tds-mobile-nav-right.active {
    right: -295px;
  }

  .tds-mobile-nav-search .tds-mobile-search-input {
    width: 50%;
  }
}

/* TD EMERALD Standards Overrides BEGIN */

/* TD EMERALD Standards Overrides BEGIN */
